<template> 
  <div class="container" :style="{'background-image': 'url(' + cover + ')'}">
    <div class="main">
      <img class="avatar" :src="avatar" alt="avatar">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cover: "",
      avatar: "",
      club: "",
    }
  },
  created() {
    const {  cover, avatar, club } = this.$route.query;
    this.cover = cover;
    this.avatar= avatar;
    this.club = club;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  position: relative;
  width: 100%;
  height: 80vw;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  .main {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: px2rem(138 * $multipleCount);
    background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a4af2-95ae-adaf-c8e7-73cfdf86cf15?x-oss-process=style/origin') no-repeat center top / cover;

    > .avatar {
      position: absolute;
      left: px2rem(6 * $multipleCount);
      bottom: px2rem(4 * $multipleCount);
      width: px2rem(84 * $multipleCount);
      height: px2rem(84 * $multipleCount);
      border: px2rem(4 * $multipleCount) solid #333;
      background-color: #ddd;
      border-radius: 50%;
    }
  }
}
</style>
